<template>
  <div class="home">
    <div class="content">
      <a href="https://forms.gle/UrfDyXWFmn9MPjKT9" target="_blank">
        <img src="../assets/img/error_report.png" style="width:120px;position: absolute;cursor: pointer;"/>
      </a>
    </div>
    <div class="titie pc">
      Please join our community,
      <img src="../assets/img/join_us.png"/>
      <a href="https://discord.com/channels/964572422210744390/" target="_blank">Click HERE</a>
    </div>
    <div class="content">
      <div class="mode">
        <div class="hea">
          RANK
        </div>
        <div class="mid">
          <div class="tool">
            <div>
              <input placeholder="Battle tag" v-model="nick_name"/>
              <img src="../assets/img/search.png" @click="rankSearch()" style="cursor: pointer;"/>
            </div>
          </div>
          <div class="rows pc">
            <i>Rank</i>
            <i>Users</i>
            <i>Ladder points (wins)</i>
          </div>
          <div class="list">
            <div class="datas" v-for="(item,index) in rankDatas" :key="item.user_uuid">
              <span style="display: flex;flex-direction: column;align-items: center;justify-content: center;"><p class="mobile">RANK NO.{{item.user_rank}}</p><p class="pc">{{item.user_rank}}</p></span>
              <span v-if="item.user_rank == 1">
                <div class="img"><img src="../assets/img/Vector1.png" style="z-index:1;"/>
                <img class="headimg" :src="item.user_profile_photo" v-if="item.user_profile_photo != null"/>
                <img class="headimg" src="../assets/img/default_logo.jpg" v-else/>
                </div>
                <p>{{item.nick_name}}</p>
              </span>
              <span v-else-if="item.user_rank == 2">
                <div class="img"><img src="../assets/img/Vector2.png" style="z-index:1;"/>
                <img class="headimg" :src="item.user_profile_photo" v-if="item.user_profile_photo != null"/>
                <img class="headimg" src="../assets/img/default_logo.jpg" v-else/>
                </div>
                <p>{{item.nick_name}}</p>
              </span>
              <span v-else>
                <div class="img">
                  <img class="headimg" :src="item.user_profile_photo" v-if="item.user_profile_photo != null"/>
                  <img class="headimg" src="../assets/img/default_logo.jpg" v-else/>
                </div>
                <p>{{item.nick_name}}</p>
              </span>
              <span>{{item.user_score}}<p class="pc"> ({{item.number_of_won}} / {{item.total_battle}})</p></span>
            </div>
          </div>
        </div>
        <div class="bot" @click="loadRank()">
          <img src="../assets/img/bottomJT.png" style="cursor: pointer;"/>
        </div>
      </div>
      <div class="mode">
        <div class="hea">
          PVP list
        </div>
        <div class="mid">
          <div class="tool">
            <div>
              <input placeholder="Canpaign name" v-model="pvpParams.game_name"/>
              <img src="../assets/img/search.png" @click="pvpSearch()" style="cursor: pointer;"/>
            </div>
            <!-- <div @click="golinkUser('createNewGame')">+ CREATE GAME</div> -->
          </div>
          <div class="rows2 pc">
            <i>Type</i>
            <i>Game name</i>
            <i>Participants</i>
          </div>
          <div class="list">
            <div class="datas2 pc" v-for="(item,index) in pvpDatas" :key="item.game_uuid">
              <div>{{item.pvp_type}}</div>
              <div><a>({{item.battle_type}})</a>{{item.title}}</div>
              <div @click="join_game(item.pvp_type, item.game_uuid,item.game_status)">
                <a>{{auto_comple_zero(item.applied_team_count)}}/{{auto_comple_zero(item.max_number_of_team)}}</a>
                <span v-if="$utils.diffDays(item.apply_period_end_date) != 0">
                  {{$utils.diffDays(item.apply_period_end_date)}}days left
                </span>
              </div>
            </div>
            <div class="datas3 mobile" v-for="(item,index) in pvpDatas" :key="'m' + item.game_uuid" @click="join_game(item.pvp_type, item.game_uuid,item.game_status)">
              <div class="left">
                <p>{{item.title}}</p>
                <div>
                  <a>{{auto_comple_zero(item.applied_team_count)}}/{{auto_comple_zero(item.max_number_of_team)}}</a>
                  <span v-if="$utils.diffDays(item.apply_period_end_date) != 0">
                    {{$utils.diffDays(item.apply_period_end_date)}}days left
                  </span>
                </div>
              </div>
              <div class="right act" v-if="item.user_joined">JOINED</div>
              <div class="right" v-else>JOIN</div>
            </div>

          </div>
        </div>
        <div class="bot" @click="loadPVP()" style="cursor: pointer;">
          <img src="../assets/img/bottomJT.png"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getActionApi } from '@/network/manageApi'
  import { mapGetters } from 'vuex'
  import Vue from 'vue'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  export default {
    name: 'Home',
    components: {
    },
    data() {
      return {
        imgQZ:`${process.env.VUE_APP_API_URL_XA}`,
        rankDatas: [],
        rankParams: {
          page: 1,
          size: 5
        },
        nick_name: '',
        rankLoadMore: 'more',
        pvpDatas: [],
        pvpParams: {
          game_name: '',
          page: 1,
          size: 10
        },
        pvpLoadMore: 'more',
      }
    },
    computed: {

    },
    created(){
      this.loadRank();
      this.loadPVP();
    },
    methods:{
      loadRank() {
        if (this.rankLoadMore === 'loading' || this.rankLoadMore === 'nomore') return;
        this.rankLoadMore = 'loading';
        if(this.nick_name != '') {
          this.rankParams.nick_name = this.nick_name
        } else {
          delete this.rankParams.nick_name;
        }
        getActionApi('/user-management/user-ranking', this.rankParams).then(res => {
          if (res.code == 200) {
            this.rankDatas = [...this.rankDatas, ...res.data.contents];
            if (res.data.page.currentPage < res.data.page.totalPage) {
              this.rankParams.page++;
              this.rankLoadMore = 'more';
            } else {
              this.rankLoadMore = 'nomore';
            }
          }
        })
      },
      auto_comple_zero(number) {
        return number.toString().length == 1?("0" + number):number
      },
      rankSearch() {
        this.rankDatas = [],
        this.rankParams.page = 1;
        this.rankLoadMore = 'more';
        this.loadRank();
      },
      loadPVP() {
        if (this.pvpLoadMore === 'loading' || this.pvpLoadMore === 'nomore') return;
        this.pvpLoadMore = 'loading';
        getActionApi('/game-management/game', this.pvpParams).then(res => {
          if (res.code == 200) {
            this.pvpDatas = [...this.pvpDatas, ...res.data.contents];
            if (res.data.page.currentPage < res.data.page.totalPage) {
              this.pvpLoadMore.page++;
              this.pvpLoadMore = 'more';
            } else {
              this.pvpLoadMore = 'nomore';
            }
          }
        })
      },
      pvpSearch() {
        this.pvpDatas = [],
        this.pvpParams.page = 1;
        this.pvpLoadMore = 'more';
        this.loadPVP();
      },
      join_game(type, uuid,status) {
        if(!Vue.ls.get(ACCESS_TOKEN)) {
          this.$router.push({path:"login"});
          return;
        }
        if(status === 'waiting') {
          if(type === 'Solo') {
            this.$router.push({path:'joinMatch',query:{uuid:uuid}});
          }  else {
            this.$router.push({path:"joinTeam",query:{uuid:uuid}});
          }
        } else if(status === 'started') {
          if(type === 'Solo') {
            this.$router.push({path:'gamePlayMatch',query:{uuid:uuid}});
          }  else {
            this.$router.push({path:"gamePlayTeam",query:{uuid:uuid}});
          }
        }
      },
      golink(item){
        this.$router.push({path:item});
      },
      golinkUser(item){
        if(Vue.ls.get(ACCESS_TOKEN))
        this.$router.push({path:item});
        else
          this.$router.push({path:"login"});
      }
    }
  }
</script>
<style scoped>
  *{font-size:16px;font-family: 'ABeeZee';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input{color:#ffffff;}
  .home{background-position: center;background-repeat: no-repeat;height:100%;background-size: auto 100%;display: flex;flex-direction: column;}
  .home .titie{display: flex;justify-content: center;align-items: center;color:#ffffff;font-size:20px;flex-shrink: 0;margin-bottom:20px;}
  .home .titie img{width:50px;height:50px;margin:0 10px;}
  .home .titie a{color:#5C6BC0;cursor: pointer;}
  .home .content{display: flex;justify-content: space-between;height:100%;margin: auto;}
  .home .content .mode{background-color: rgba(144, 145, 114, 0.5);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 30px;width:48%;height:100%;padding:30px;display: flex;flex-direction: column;align-items: center;}
  .home .content .mode .hea{flex-shrink: 0;font-size: 30px;text-shadow: 1px 1px #1DF200,-1px 1px #1DF200,1px -1px #1DF200,-1px -1px #1DF200;margin-bottom:20px;}
  .home .content .mode .mid{height:100%;display: flex;flex-direction: column;width:100%;}
  .home .content .mode .mid .tool{flex-shrink: 0;margin-bottom:10px;display: flex;align-items: center;justify-content: space-between;}
  .home .content .mode .mid .tool div{display: flex;align-items: center;}
  .home .content .mode .mid .tool div:nth-child(2){background-color: #1DF200;padding:4px 16px;font-size: 14px;cursor: pointer;}
  .home .content .mode .mid .tool input{background-color: transparent;border:none;border-bottom: 1px solid #F0F0F0;text-align: center;height:30px;width:140px;}
  .home .content .mode .mid .tool input::-webkit-input-placeholder{color:#D0D0D0;}
  .home .content .mode .mid .rows{flex-shrink: 0;display: flex;background-color: #1DF200;margin-top:2px;}
  .home .content .mode .mid .rows i{text-align: center;padding:5px 0;font-size:14px;}
  .home .content .mode .mid .rows i:nth-child(1){width:20%;}
  .home .content .mode .mid .rows i:nth-child(2){width:30%;}
  .home .content .mode .mid .rows i:nth-child(3){width:50%;}
  .home .content .mode .mid .rows2{flex-shrink: 0;display: flex;background-color: #1DF200;margin-top:2px;}
  .home .content .mode .mid .rows2 i{text-align: center;padding:5px 0;font-size:14px;}
  .home .content .mode .mid .rows2 i:nth-child(1){width:20%;}
  .home .content .mode .mid .rows2 i:nth-child(2){width:50%;}
  .home .content .mode .mid .rows2 i:nth-child(3){width:30%;text-align: left;}
  .home .content .mode .mid .list{height:52vh;overflow-y: auto;flex-shrink: 1;}
  .home .content .mode .mid .list .datas{display: flex;background-color: rgba(255, 255, 255, 0.8);border: 1px solid #1DF200;margin-top:2px;}
  .home .content .mode .mid .list .datas span{padding:8px 0;text-align: center;font-size: 18px;}
  .home .content .mode .mid .list .datas:nth-child(1) .img img:nth-child(2){margin-bottom:6px;}
  .home .content .mode .mid .list .datas:nth-child(2) .img img:nth-child(2){margin-bottom:6px;}
  .home .content .mode .mid .list .datas span:nth-child(1){width:20%;font-style: italic;flex-shrink: 0;}
  .home .content .mode .mid .list .datas span:nth-child(2){width:30%;display: flex;align-items: center;text-align: left;}
  .home .content .mode .mid .list .datas span:nth-child(2) .img{width:30px;height:30px;margin-right:6px;display: flex;align-items: center;flex-direction: column;justify-content: center;}
  .home .content .mode .mid .list .datas span:nth-child(2) .img img:nth-child(2){margin-top:-6px;}
  .home .content .mode .mid .list .datas span:nth-child(2) p{overflow: hidden;word-break: keep-all;white-space: nowrap;text-overflow: ellipsis;}
  .home .content .mode .mid .list .datas span:nth-child(3){width:50%;font-style: italic;color: #5C6BC0;flex-shrink: 0;}
  .headimg {width:30px;height:30px;border-radius: 50%;border:2px #ffffff solid;flex-shrink: 0;}
  .home .content .mode .mid .list .datas2{display: flex;background-color: rgba(255, 255, 255, 0.8);border: 1px solid #1DF200;margin-top:2px;align-items: center;}
  .home .content .mode .mid .list .datas2 div{padding:8px 0;text-align: center;font-size: 18px;}
  .home .content .mode .mid .list .datas2 div:nth-child(1){width:20%;font-style: italic;flex-shrink: 0;}
  .home .content .mode .mid .list .datas2 div:nth-child(2){width:50%;display: flex;align-items: center;font-size: 14px;}
  .home .content .mode .mid .list .datas2 div:nth-child(2) a{color:#5C6BC0;}
  .home .content .mode .mid .list .datas2 div:nth-child(3){cursor: pointer;width:30%;color: #5C6BC0;flex-shrink: 0;display: flex;flex-direction: column;align-items: center;padding-right:50px;background-image: url(../assets/img/right-btn.png);background-repeat: no-repeat;background-position: center right 20px;}
  .home .content .mode .mid .list .datas2 div:nth-child(3) a{color:#0500FF;font-size:18px;}
  .home .content .mode .mid .list .datas2 div:nth-child(3) span{color:#828282;font-size:14px;}
  .home .content .mode .bot{flex-shrink: 0;}
  .home .content .mode .bot img{width:51px;margin-top:10px;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
    .home{background-color: #452D2D;padding-top:50px;}
    .home .content{display: flex;flex-direction: column;padding:20px;width:100%;}
    .home .content .mode{width:100%;border-radius: 8px;padding:6px;margin-bottom: 20px;background: rgba(96, 98, 34, 0.5);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
    .home .content .mode .mid .list .datas{align-items: center;}
    .home .content .mode .mid .list .datas span:nth-child(1){width:30%;font-size:14px;}
    .home .content .mode .mid .list .datas span:nth-child(2){width:50%;font-size:14px;}
    .home .content .mode .mid .list .datas span:nth-child(3){width:20%;font-size:14px;}
    .home .content .mode .mid .list{height:auto; min-height: 30px;}
    .datas3{display: flex;background-color: rgba(255, 255, 255, 0.8);border: 1px solid #1DF200;margin-top:2px;align-items: center;justify-content: space-between;}
    .datas3 .left{padding:6px;}
    .datas3 .left div{font-size:12px;color:#828282;}
    .datas3 .left div a{color: #0500FF;margin-right:20px;}
    .datas3 .right{flex-shrink: 0;background: #0500FF;border-radius: 14px;margin-right:10px;width: 70px;height:28px;color:#ffffff;display: flex;align-items: center;justify-content: center;}
    .datas3 .act{background: #1DF200;color: #000000;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
    .home{background-image: url(../assets/img/bg.jpg);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
    .home{background-image: url(../assets/img/bg.jpg);}
  }
</style>
