<template>
  <div class="body" v-loading="formLoading">
    <div class="bg"></div>
    <div class="content">
      <div class="title">Welcome to D2RPVP</div>
      <div class="info">
        <div class="item">
          <span>Email</span>
          <input v-model="param.email"/>
        </div>
        <div class="item msg-item">
          <span>Password</span>
          <input v-model="param.password" type="password" class="password"/>
        </div>
        <div class="msg">{{msg}}</div>
        <div class="btn" @click="loginPassword">Log-in</div>
        <div class="link"><a href="/findpw">FIND PW</a></div>
        <div class="link"><a href="/register">NEW REGISTER</a></div>
      </div>
    </div>
    <hash-bottom></hash-bottom>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { postActionApi,getActionApi } from '@/network/manageApi'
  import { getAction,postAction } from '@/network/manage'
  import { ACCESS_TOKEN,HEAD_IMG_URL,USER_UUID } from '@/store/mutation-types'
  import { mapGetters } from 'vuex'
  import HashBottom from "components/HashBottom"
  export default {
    name: 'Login',
    components: {
      HashBottom
    },
    data() {
      return {
        msg:'',
        loginType:0,
        formLoading:false,
        certName:'',
        certRemark:'',
        param:{email:'',password:''},
        url: {

        }
      }
    },
    computed: {
      ...mapGetters(['token']),
    },
    created(){
      if(this.token !== null && this.token !== '') {
        window.location.href='/'
      }
    },
    methods:{
      loginPassword(){
        this.msg = '';
        if(this.param.email == ''){
          this.msg = 'Email is required!';
        } else if(this.param.password == '') {
          this.msg = 'Password is required!';
        } else {
          const that = this;
          that.formLoading = true;
          postActionApi('/user-management/login',this.param).then((res) => {
            that.formLoading = false;
            if (res.code == 200) {
              Vue.ls.set(ACCESS_TOKEN, res.data.token, 10 * 60 * 60 * 1000)
              //查询会员信息
              that.formLoading = true;
              getActionApi('/user-management/my-information').then((res) => {
                that.formLoading = false;
                if(res.code == 200){
                  if(res.data.contents.length > 0 && res.data.contents[0].user_profile_photo) {
                    Vue.ls.set(HEAD_IMG_URL, res.data.contents[0].user_profile_photo, 10 * 60 * 60 * 1000)
                  }
                  Vue.ls.set(USER_UUID, res.data.contents[0].user_uuid, 10 * 60 * 60 * 1000)
                  window.location.href='/'
                } else {
                  that.msg = res.message;
                  that.formLoading = false;
                }
              }).catch(res => {
                that.msg = res.message;
                that.formLoading = false;
              })
            } else {
              that.msg = res.message;
              that.formLoading = false;
            }
          }).catch(res => {
            that.msg = res.message;
            that.formLoading = false;
          })
        }
      },
      goPage(url){
        this.$router.go(url);
      },
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'ABeeZee';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;margin:auto;align-items: center;padding:80px 0;}
  .title{font-size: 30px;color:#00FF00;margin-bottom:30px;}
  .password{background-image: url(../assets/img/password.png);background-repeat: no-repeat;background-position: right 10px center;background-size: 20px;}
  .password:-webkit-autofill{
    -webkit-text-fill-color: #ededed !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
    background-color:transparent;
    background-image: url(../assets/img/password.png);background-repeat: no-repeat;background-position: right 10px center;background-size: 20px;
    transition: background-color 50000s ease-in-out 0s;
  }
  .msg{font-family: 'ABeeZee';color: #C90000;font-weight: bold;text-align: left;margin-top:20px;}
  .msg-item{margin-bottom:0 !important;}
  .login-type{display: flex;margin-bottom: 20px;}
  .login-type span{background: #D9D9D9;font-family: 'ABeeZee';cursor: pointer;font-weight: bold;border-radius: 18px;width:110px;height:40px;display: flex;align-items: center;justify-content: center;margin-right:20px;}
  .login-type .act{background: #01388B;color:#ffffff;}
  .link{font-family: 'ABeeZee';color: #0020C9;text-align: left;letter-spacing: -0.6px;font-weight: bold;font-size:15px;margin-bottom:5px;text-align: center;}
  .content .info{width:600px;padding:20px 80px;border-radius: 10px;}
  .content .info .item{display: flex;flex-direction: column;margin-bottom:40px;align-items: flex-start;}
  .content .info .item span{font-size:14px;margin-bottom:6px;color:#ffffff;}
  .content .info .item input{border: 1px solid #ffffff;border-radius: 8px;width:100%;color:#ffffff;height:38px;padding:0 10px;background-color:transparent;}
  input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
      -webkit-text-fill-color: #ededed !important;
      -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
      background-color:transparent;
      background-image: none;
       transition: background-color 50000s ease-in-out 0s;
  }
  .content .info .item textarea{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:160px;padding:10px;}
  .content .info .item .bord{border-radius: 8px;border: 1px solid #5F5F5F;}
  .content .info .item .bord input{border-width: 0px;}
  .content .info .item .bord textarea{border-width: 0px;border-top: 1px solid #5F5F5F;border-radius: 0px;}
  .content .info .btn{width: 100%;cursor: pointer;height: 40px;line-height:1;margin:30px 0 30px 0;background: #00FF00;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  a{text-decoration: none;color:#5C6BC0;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .body{height:100vh;}
    .title{margin:100px 0 40px 0 !important;font-size:24px !important;}
    .content{padding:0 0 30px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .address{display: flex;flex-direction: column;align-items: flex-start;text-align: left;padding:0 20px;}
    .content .info .item span{margin-bottom:10px;}
    .content .info .item{margin-bottom:20px;}
    .msg{font-weight: bold;text-align: center;}
    .link{text-align: center;margin:10px 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
  }
</style>
