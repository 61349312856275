<template>
  <div class="body">
    <div class="content">
      <div class="info">
        <upload
            class="avatar-uploader"
            :action="getUploadUrl"
            :headers="headers"
            :show-file-list="false"
            :multiple="false"
            accept="image/*"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img class="headImg" :src="myData.user_profile_photo" v-if="myData.user_profile_photo != null"/>
            <img class="headImg" src="../assets/img/default_logo.jpg" v-else/>
          </upload>
        <h1>{{myData.nick_name}}</h1>
        <div>
          <p>
             Rank<br/>
             {{myData.user_rank}}
          </p>
          <p>
             Ladder<br/>
             {{myData.user_score}}
          </p>
          <p>
             Wins<br/>
             {{myData.number_of_won}}/{{myData.total_battle}}
          </p>
          <p>
             Toxic<br/>
             {{myData.toxic_score}}
          </p>
        </div>
      </div>
      <div class="menu">
        <span :class="menuIndex == 0 ? 'act' : ''" @click="menuIndex = 0">Information</span>
        <span :class="menuIndex == 1 ? 'act' : ''" @click="menuIndex = 1">Your game</span>
      </div>
      <div class="item1" v-if="menuIndex == 0">
        <div class="form" v-if="!changePass">
          <div class="title">E-mail (ID)</div>
          <input class="input" readonly v-model="myData.emailSensitive"/>
          <div class="title">Battle TAG (GAME NAME)</div>
          <input class="input" readonly v-model="myData.nick_name"/>
          <div class="link">
            <a @click="changePass = true">Change password</a>
          </div>
        </div>
        <div class="changePass" v-if="changePass">
          <div class="title">New Password</div>
          <div class="input"><input :type="isPass?'password':'text'" v-model="password"/><img @click="isPass = !isPass" src="../assets/img/password.png"/></div>
          <div class="title">Confirm Password</div>
          <div class="input"><input :type="isPass?'password':'text'" v-model="password2"/><img @click="isPass = !isPass" src="../assets/img/password.png"/></div>
          <div class="link">
            <a class="btn" @click="update_pwd">SAVE</a>
          </div>
        </div>
      </div>
      <div class="item2" v-if="menuIndex == 1">
        <div class="table">
          <div class="item">
            <span>Title</span>
            <span>Status</span>
            <span>Ladder earnings</span>
            <span>Wins</span>
          </div>
          <div class="item" v-for="(item,index) in myGameDatas" :key="item.game_uuid">
            <span><a href="javascript:void(0)" @click="join_game(item.pvp_type, item.game_uuid,item.game_status)">{{item.title}}</a></span>
            <span v-if="item.game_status == 'waiting'"><button class="waiting">{{item.game_status}}</button></span>
            <span v-if="item.game_status == 'started'"><button class="started">{{item.game_status}}</button></span>
            <span v-if="item.game_status == 'end'"><button class="end">{{item.game_status}}</button></span>
            <span v-if="item.game_status == 'waiting'">-</span>
            <span v-else>{{item.earned_score >= 0?'+'+item.earned_score:item.earned_score}}</span>
            <span v-if="item.game_status == 'waiting'">-</span>
            <span v-else>{{auto_comple_zero(item.winning_number)}}/{{auto_comple_zero(item.total_battle)}}</span>
          </div>
        </div>
        <div class="pager" v-if="pageArray.length > 0">
          <a @click="go_page('first')"><i class="el-icon-d-arrow-left"></i></a>
          <a @click="go_page('pre')"><i class="el-icon-arrow-left"></i></a>
          <a @click="go_page(item)" :class="item == pageObj.currentPage?'act':''" v-for="item in pageArray">{{item}}</a>
          <a @click="go_page('next')"><i class="el-icon-arrow-right"></i></a>
          <a @click="go_page('end')"><i class="el-icon-d-arrow-right"></i></a>
        </div>
      </div>
    </div>
    <div class="confirmWindow" v-if="scoreBoard_match">
      <div class="con">
        <div class="head">
          <a>　</a>
          <p>Score board</p>
          <b @click="scoreBoard_match = false">×</b>
        </div>
        <div class="list">
          <div class="item header">
            <span>Rank</span>
            <span>User</span>
            <span>Total round</span>
            <span>PVP Total</span>
            <span>Rank score</span>
          </div>
          <div class="item" v-for="(item,index) in score_datas_match">
            <span>{{item.rank}}</span>
            <span class="users">
              <div class="imgs qs">
                <img src="../assets/img/Vector1.png" class="pc" v-if="item.rank == 1"/>
                <img src="../assets/img/Vector2.png" class="pc" v-if="item.rank == 2"/>
                <img class="headimg" :src="item.members[0].profile_photo" v-if="item.members[0].profile_photo != null"/>
                <img class="headimg" src="../assets/img/default_logo.jpg" v-else/>
              </div>
              <p>{{item.members[0].nick_name}}</p>
            </span>
            <span class="round">
              {{item.scores.number_of_games}}
              <a>{{item.scores.number_of_wins}} wins</a>
            </span>
            <span>{{item.scores.pvp_win}} : {{item.scores.pvp_lose}}</span>
            <span>{{item.scores.rank_score}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="confirmWindow" v-if="scoreBoard_team">
      <div class="con">
        <div class="head">
          <a>　</a>
          <p>Score board</p>
          <b @click="scoreBoard_team = false">×</b>
        </div>
        <div class="list">
          <div class="item header">
            <span>Rank</span>
            <span>User</span>
            <span>Total round</span>
            <span>PVP Total</span>
            <span>Rank score</span>
          </div>
          <div class="item" v-for="(item,index) in score_datas_team">
            <span>{{item.rank}}</span>
            <span class="users">
              <div class="imgs qs" v-if="item.rank == 1">
                <img src="../assets/img/Vector1.png"/>
              </div>
              <div class="imgs qs" v-if="item.rank == 2">
                <img src="../assets/img/Vector2.png"/>
              </div>
              <p>{{item.team_name}}</p>
            </span>
            <span class="round">
              {{item.scores.number_of_games}}
              <a>{{item.scores.number_of_wins}} wins</a>
            </span>
            <span>{{item.scores.pvp_win}} : {{item.scores.pvp_lose}}</span>
            <span>{{item.scores.rank_score}}</span>
          </div>

        </div>
      </div>
    </div>
    <HashBottom></HashBottom>
  </div>
</template>
<script>
  import { Upload } from 'element-ui'
  import Vue from 'vue'
  import { postActionApi,getActionApi,putActionApi } from '@/network/manageApi'
  import { getAction,postAction } from '@/network/manage'
  import { ACCESS_TOKEN,HEAD_IMG_URL } from '@/store/mutation-types'
  import { mapGetters } from 'vuex'
  import HashBottom from "components/HashBottom"
  export default {
    name: 'MyPage',
    components: {
      Upload,HashBottom
    },
    data() {
      return {
        menuIndex:0,
        isPass:true,
        changePass:false,
        myData: {},
        headers: {Authorization: this.$ls.get(ACCESS_TOKEN)},
        loding:null,
        password: '',
        password2: '',
        pageData: {size:10, page:1},
        myGameDatas: [],
        pageObj: {},
        pageArray: [],
        load_flag:false,
        scoreBoard_match: false,
        score_datas_match: [],
        scoreBoard_team: false,
        score_datas_team: []
      }
    },
    computed: {
      getUploadUrl() {
        return process.env.VUE_APP_API_URL + "/file-upload/upload"
      },
      ...mapGetters(['useruuid'])
    },
    created(){
      this.init()
    },
    methods:{
      show_score_board_team(uuid) {
        this.loding = this.$loading.service()
        getActionApi('/game-management/campaign-ranking?game_uuid=' + uuid).then(res => {
          this.loding.close()
          if (res.code == 200) {
            this.score_datas_team = res.data.contents
            for(let item of this.score_datas_team) {
              item.scores.rank_score = item.scores.campaign_earned_score
            }
            this.scoreBoard_team = true
          }
        })
      },
      show_score_board_match(uuid) {
        this.loding = this.$loading.service()
        getActionApi('/game-management/campaign-ranking?game_uuid=' + uuid).then(res => {
          this.loding.close()
          if (res.code == 200) {
            this.score_datas_match = res.data.contents
            for(let item of this.score_datas_match) {
              item.scores.rank_score = item.scores.campaign_earned_score
            }
            this.scoreBoard_match = true
          }
        })
      },
      auto_comple_zero(number) {
        return number.toString().length == 1?("0" + number):number
      },
      update_pwd() {
        const that = this;
        that.password = that.password
        that.password2 = that.password2
        if(that.password == ''){
          this.$message.error('New Password is required!');
        }else if(that.password.length < 6){
          this.$message.error('The minimum password length is 6 digits');
        }else if(that.password2 == ''){
          this.$message.error('Confirm Password is required!');
        }else if(that.password2 != that.password){
          this.$message.error('The two passwords are inconsistent!');
        }else{
          this.formLoading = true;
          let params = {
            new_password:that.password
          }
          putActionApi("/user-management/password-change", params).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'Password Change successful!',
                type: 'success'
              });
              this.changePass = false
            } else {
              that.$message.error(res.message);
            }
          }).catch(err => {
            that.$message.warning(err.message);
          }).finally(() => {
            that.formLoading = false;
          })
        }
      },
      init() {
        getActionApi('/user-management/my-information').then(res => {
          if (res.code == 200) {
            this.myData = res.data.contents[0]
            this.myData.emailSensitive = this.email_sensitive(this.myData.email)
          }
        })
        getActionApi('/game-management/my-game',this.pageData).then(res => {
          if (res.code == 200) {
            this.myGameDatas = res.data.contents
            this.pageObj = res.data.page
            this.init_page_info()
          }
        })
      },
      go_page(type) {
        if(!this.load_flag) {
          this.load_flag = true
          if(type == 'pre') {
            this.pageData.page = (this.pageData.page - 1 > 0)?this.pageData.page - 1:1
          } else if(type == 'next') {
            this.pageData.page = (this.pageData.page + 1 < this.pageObj.totalPage)?this.pageData.page + 1:this.pageObj.totalPage
          } else if(type == 'first') {
            this.pageData.page = 1
          } else if(type == 'end') {
            this.pageData.page = this.pageObj.totalPage
          } else {
            this.pageData.page = type
          }
          getActionApi('/game-management/my-game',this.pageData).then(res => {
            this.load_flag = false
            if (res.code == 200) {
              this.myGameDatas = res.data.contents
              this.pageObj = res.data.page
              this.init_page_info()
            }
          })
        }
      },
      init_page_info() {
        this.pageArray = []
        let start = 1
        let end = this.pageObj.totalPage
        if(this.pageObj.totalPage > 6) {
          if(this.pageObj.currentPage > 3) {
            start = this.pageObj.currentPage - 2
            if(this.pageObj.totalPage - start < 5) {
                start = this.pageObj.totalPage - 5
            }
          }
          end = 6
        }
        for(let i=0;i < end; i++) {
          this.pageArray.push(start++)
        }
      },
      join_game(type, uuid,status) {
        if(!Vue.ls.get(ACCESS_TOKEN)) {
          this.$router.push({path:"login"});
          return;
        }
        if(status === 'waiting') {
          if(type === 'solo') {
            this.$router.push({path:'joinMatch',query:{uuid:uuid}});
          }  else {
            this.$router.push({path:"joinTeam",query:{uuid:uuid}});
          }
        } else if(status === 'started') {
          if(type === 'solo') {
            this.$router.push({path:'gamePlayMatch',query:{uuid:uuid}});
          }  else {
            this.$router.push({path:"gamePlayTeam",query:{uuid:uuid}});
          }
        } else if(status === 'end') {
          if(type === 'solo') {
            this.show_score_board_match(uuid)
          }  else {
            this.show_score_board_team(uuid)
          }
        }
      },
      email_sensitive(email) {
        let str = email.split("@");
        return str[0].substr(0, str[0].length - 2) + "*****" + str[1].substr(2)
      },
      goPage(url){
        this.$router.go(url);
      },
      handleAvatarSuccess(res, file) {
        this.loding.close()
        getActionApi('/file-upload/display?user_uuid=' + this.useruuid + '&image_name=' + res.data.upload_path).then(result => {
          if (result.code == 200) {
              this.myData.user_profile_photo = result.data.url
              Vue.ls.set(HEAD_IMG_URL, result.data.url, 10 * 60 * 60 * 1000)
              this.$store.commit('SET_HEADIMGURL', result.data.url)
              putActionApi('/user-management/my-information',{"user_profile_photo":result.data.url})
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('Upload avatar size cannot exceed 5MB!')
        } else {
          this.loding = this.$loading.service()
        }
        return isLt5M
      }
    }
  }
</script>

<style scoped>
  .headimg {width:30px;height:30px;border-radius: 50%;border:1px #c1c1c1 solid;flex-shrink: 0;}
  .confirmWindow{position: fixed;top:0;left:0;width:100vw;height:100vh;display: flex;align-items:center;justify-content: center;background: #000000c7;}
  .confirmWindow .con{border:2px solid #00FF00;border-radius: 10px;background-color: #000000;min-width:900px;color:#ffffff;display: flex;flex-direction: column;padding:20px;align-items: center;}
  .confirmWindow .con p{font-size:26px;margin:30px 0;}
  .confirmWindow .con .close{width:100%;display: flex;justify-content: flex-end;}
  .confirmWindow .con .close b{width:24px;height:24px;font-size:14px;display: flex;justify-content: center;align-items: center;color:#4A4A4A;border:2px solid #D0D0D0;background-color: #ffffff;cursor: pointer;border-radius: 4px;}
  .confirmWindow .con .btn{width:120px;cursor: pointer;height: 40px;line-height:1;margin:10px 20px 30px 20px;background:#0500FF;color:#ffffff;border-radius: 8px;display: flex;align-items: center;justify-content: center;}
  .confirmWindow .con .head{width:100%;display: flex;justify-content: flex-end;align-items: center;}
  .confirmWindow .con .head p{color:#ffffff;font-size:20px;text-align: center;width:100%;margin:0;}
  .confirmWindow .con .head a{width:24px;flex-shrink: 0;}
  .confirmWindow .con .head b{width:24px;flex-shrink: 0;height:24px;font-size:14px;display: flex;justify-content: center;align-items: center;color:#4A4A4A;border:2px solid #D0D0D0;background-color: #ffffff;cursor: pointer;border-radius: 4px;}
  .confirmWindow .list{max-height: 380px;margin:20px 0;width:100%;overflow-y: auto;}
  .confirmWindow .list .item{display: flex;align-items: center;background-color: #ffffff;margin-bottom: 2px;color:#000000;height:44px;}
  .confirmWindow .list .item:last-child{margin-bottom:0;}
  .confirmWindow .list .item span:nth-child(1){width:12%;}
  .confirmWindow .list .item span:nth-child(2){width:28%;}
  .confirmWindow .list .item span:nth-child(3){width:20%;}
  .confirmWindow .list .item span:nth-child(4){width:20%;}
  .confirmWindow .list .item span:nth-child(5){width:20%;}
  .confirmWindow .list .users{display: flex;align-items: center;}
  .confirmWindow .list .users .imgs{flex-shrink: 0;width:30px;height:30px;margin-right:6px;display: flex;align-items: center;flex-direction: column;justify-content: center;}
  .confirmWindow .list .qs{padding-bottom:6px;}
  .confirmWindow .list .users .imgs img:nth-child(2){margin-top:-6px;z-index:1;}
  .confirmWindow .list .users .imgs img:nth-child(1){z-index:2;}
  .confirmWindow .list .users p{font-size:16px;width:100%;margin:0;}
  .confirmWindow .list .round{flex-direction: column;display: flex;line-height: 1;}
  .confirmWindow .list .round a{color:#0500FF;font-size:12px;margin-top:5px;}
  .confirmWindow .header{background-color:#00FF00 !important;}
  .confirmWindow .header span{display: flex;flex-direction: column;align-items: center;justify-content: center;line-height: 1;}
  .confirmWindow .header span a{font-size:12px;color:#000000;line-height: 1;}
  .confirmWindow .btnView{display: flex;justify-content: center;align-items: center;margin-top:20px;}
  .confirmWindow .warring{background-color: #FF0000 !important;}
  .confirmWindow .scoremsg{color:#FF0000;}
  .confirmWindow .tils{color:#00FF00;margin-bottom:10px;}
  .confirmWindow .dash{border-bottom:2px dashed #00FF00;width:80%;margin:40px 0 10px 0;height:2px;}
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'ABeeZee';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;align-items: center;margin:auto;}
  .content .info{display: flex;flex-direction: column;align-items: center;}
  .content .info .headImg{width:100px;height:100px;border-radius: 50%;border:4px solid #ffffff;margin-top:20px;}
  .content .info h1{color:#ffffff;font-size:26px;margin:20px 0;}
  .content .info div{display: flex;color:#5C6BC0;font-style: italic;}
  .content .info div p{width:80px;text-align: center;}
  .content .menu{display: flex;}
  .content .menu span{color:#ffffff;margin:20px;padding:6px 4px;border-bottom: 2px solid transparent;cursor: pointer;}
  .content .menu .act{color:#00FF00;border-bottom: 2px solid #00FF00;}
  .content .item1{width:500px;color:#ffffff;display: flex;justify-content: flex-start;text-align: left;}
  .content .item1 .form{width:100%;}
  .content .item1 .changePass{width:100%;}
  .content .item1 .changePass input{height:34px;}
  .content .item1 .input{border: 1px solid #C4C4C4;border-radius: 6px;width:100%;display: flex;align-items: center;padding-left:5px;}
  .content .item1 .input img{margin-right:10px;cursor: pointer;flex-shrink: 0;width:20px;}
  .content .item1 .title{margin:20px 0 6px 3px;}
  .content .item1 input{border: none;background-color: transparent;height:36px;width:100%;color:#ffffff;}
  .content .item1 .link{width:100%;padding:30px 0;display: flex;justify-content: center;align-items: center;}
  .content .item1 .link a{text-decoration: underline;cursor: pointer;color:#5C6BC0;}
  .content .item1 .link .btn{color:#1DF200;font-size:18px;border:1px solid #1DF200;text-decoration: none;padding:2px 20px;cursor: pointer;}
  .content .item2{width:800px;color:#ffffff;display: flex;flex-direction: column;align-items: center;}
  .content .item2 .table{border: 1px solid #1DF200;width:100%;display: flex;flex-direction: column;border-radius: 10px;}
  .content .item2 .table .item{display: flex;align-items: center;border-top:1px solid #ffffff;}
  .content .item2 .table .item:nth-child(1){border-top-width: 0;}
  .content .item2 .table .item span{display: flex;align-items: center;justify-content: center;height:50px;}
  .content .item2 .table .item span:nth-child(1){width:40%;}
  .content .item2 .table .item span:nth-child(2){width:20%;}
  .content .item2 .table .item span:nth-child(3){width:20%;}
  .content .item2 .table .item span:nth-child(4){width:20%;}
  .content .item2 .table .item a{color:#ffffff;text-decoration: none;}
  .content .item2 .table .item a:hover{color:#5C6BC0;text-decoration: underline;}
  .content .item2 .table .item button{width:80px;height:36px;border:none;display: flex;justify-content: center;align-items: center;border-radius: 18px;}
  .end{background-color: #D2D2D2;}
  .started{background-color: #00FF00;}
  .waiting{background-color: #0020C9;color:#ffffff;}
  .content .item2 .pager{display: flex;}
  .content .item2 .pager a{color:#ffffff;margin:10px 10px;cursor: pointer;}
  .content .item2 .pager a:hover{color:#00FF00;text-decoration: underline;}
  .content .item2 .pager .act{color:#00FF00;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .body{min-height:100vh;padding-top:60px;}
    .body *{font-size:14px;}
    .content .info .headImg{width:70px;height:70px;}
    .content .info h1{margin:0 0 10px 0;font-weight: normal;}
    .content .info div{color:#00FF00;}
    .content .item1{width:94vw;}
    .content .item2{width:94vw;}
    .content .item2 .table .item button{width:70px;height:30px;}
    .confirmWindow .con{width:96vw;min-width:0;}
    .confirmWindow .list .users{flex-direction: column;}
    .confirmWindow .list .users .imgs{height:14px;margin-right:0px;}
    .confirmWindow .list .users .imgs img{width:20px;height:20px;}
    .confirmWindow .list .users .imgs img:nth-child(2){margin-top:0;}
    .confirmWindow .list .users p{line-height:1;font-size: 14px;}
    .confirmWindow .list .qs{padding-bottom:0;}
    .confirmWindow .dash{width:100%;margin:30px 0 10px 0;}
    .confirmWindow .con .btn{width:100px;height: 36px;margin: 10px 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
  }
</style>
