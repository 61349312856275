import Vue from 'vue'
import axios from 'axios'
import { Notification, MessageBox } from 'element-ui'

// 创建 axios 实例
const service = axios.create({
  timeout: 10000
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    Notification.error({title: 'system prompt', message: error.message })
  }
  return Promise.reject(error)
}

service.interceptors.request.use(
  config => {
    config.headers['Apikey'] = '309d7af7-7842-4fe7-ac60-0977a7c66bbc'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use((response) => {
  return response.data
}, err)

export default service
