import axios from "@/utils/request"
import axiosFile from "@/utils/requestFile"

//post
export function postAction(url, parameter) {
  return axios({
    url: url,
    method:'post' ,
    data: parameter
  })
}

//put
export function putAction(url, parameter) {
  return axios({
    url: url,
    method:'put',
    data: parameter
  })
}

//get
export function getAction(url, parameter) {
  return axios({
    url: url,
    method: 'get',
    params: parameter
  })
}

//delete
export function deleteAction(url, parameter) {
  return axios({
    url: url,
    method: 'delete',
    data: parameter
  })
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter){
  return axios({
    url: url,
    params: parameter,
    method:'get' ,
    responseType: 'blob'
  })
}

//post
export function postFileAction(url, parameter) {
  return axiosFile({
    url: url,
    method:'post' ,
    data: parameter
  })
}
