class Common {

	/**
	 * @description 日期格式化
	 */
	format(date, fmt) {
		if (typeof date !== 'object') {
			date = new Date(date);
		}

		fmt = fmt || 'yyyy-MM-dd hh:mm:ss'
		let o = {
			"M+": date.getMonth() + 1, //月份
			"d+": date.getDate(), //日
			"h+": date.getHours(), //小时
			"m+": date.getMinutes(), //分
			"s+": date.getSeconds(), //秒
			"q+": Math.floor((date.getMonth() + 3) / 3), //季度
			"S": date.getMilliseconds() //毫秒
		};
		if (/(y+)/.test(fmt))
			fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
		for (let k in o)
			if (new RegExp("(" + k + ")").test(fmt))
				fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k])
					.length)));
		return fmt;
	}

  diffDays(date) {
    if (typeof date !== 'object') {
    	date = new Date(date + " 23:59:59");
    }
    const now = new Date();
    if (now > date) return 0;
    else if (now === date) return 1;
    else {
      const days = (date - now) / (1 * 24 * 60 * 60 * 1000)
      return Math.ceil(days)
    }
  }

  os() {
  	let ua = navigator.userAgent,
  		isWindowsPhone = /(?:Windows Phone)/.test(ua),
  		isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
  		isAndroid = /(?:Android)/.test(ua),
  		isFireFox = /(?:Firefox)/.test(ua),
  		isChrome = /(?:Chrome|CriOS)/.test(ua),
  		isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
  		isPhone = /(?:iPhone)/.test(ua) && !isTablet,
  		isPc = !isPhone && !isAndroid && !isSymbian;

  	return {
  		isTablet: isTablet,
  		isPhone: isPhone,
  		isAndroid: isAndroid,
  		isPc: isPc
  	};
  }
  // pc 端
  isPc() {
   	const { isPc } = this.os();
  	return isPc;
  }

}

export default new Common()
