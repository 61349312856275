import Vue from 'vue'
import Storage from 'vue-ls'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/permission'
import $utils from '@/utils/utils.js'
import { Message, MessageBox, Notification, Loading, Icon} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

Vue.config.productionTip = false

const storageOptions = {
  namespace: 'hash_',
  name: 'ls',
  storage: 'local',
}
Vue.use(Storage, storageOptions)

locale.use(lang)
Vue.use(Loading)
Vue.use(Icon)

Vue.prototype.$utils = $utils
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
Vue.prototype.$messageBox = MessageBox
Vue.prototype.$notification = Notification
Vue.prototype.$loading = Loading

new Vue({
  render: h => h(App),
	router,
  store
}).$mount('#app')
