<template>
  <div class="body" v-loading="formLoading">
    <div class="content">
      <div class="title"><a>({{game_data.battle_type}})</a> {{game_data.title}}</div>
      <div class="btn act" @click="isConfirm = true" v-if="isMaster">DELETE</div>
      <div class="info">
        <div class="itemp">
          <p>Rules</p>
        </div>
        <textarea style="height:80px;resize:none;" class="input pc" readonly v-model="game_data.rules"></textarea>
        <div class="itemp">
          <p>PVP type : {{game_data.pvp_type}} Match</p>
        </div>
        <div class="itemp">
          <p>Application period :  {{game_data.apply_period_start_date}} ~ {{game_data.apply_period_end_date}}</p>
        </div>
        <div class="itemp">
          <p>Campaign period :  {{game_data.campaign_period_date_start}} ~ {{game_data.campaign_period_date_end}}</p>
        </div>
        <div class="itemp">
          <p>Paticipants : {{auto_comple_zero(game_data.applied_team_count)}}  / {{auto_comple_zero(game_data.max_number_of_team)}} teams, {{auto_comple_zero(apped_number)}} / {{auto_comple_zero(game_data.max_number_of_team * game_data.team_participants)}} users</p>
        </div>
        <div class="listDiv">
          <div class="header">
            <span>Team name</span>
            <span>Ladder points (wins)</span>
            <span>Members</span>
            <span></span>
          </div>
          <div class="list">
            <div class="item" v-for="item,index in game_data.team_detail" :key="index">
              <span style="color:#5C6BC0;cursor: pointer;" @click="show_team_detail(item.team,item.team_name,item.members)">
                {{item.team_name}}
              </span>
              <span>{{item.user_score}} ({{item.profile_number_of_won}} / {{item.profile_total_battle}})</span>
              <span>{{item.members.length}}/{{game_data.team_participants}}</span>
              <span>
                <b @click="delete_show_team(item.team, item.team_name)" style="background-color: #ff0000;" v-if="(isMaster && my_team_uuid != item.team) || (!isMaster && isLeader && my_team_uuid == item.team)">Delete</b>
                <b @click="join_show_team(item.team, item.team_name)" v-if="item.members.length < game_data.team_participants && !isJoin">Join</b>
                <b class="act" v-if="item.members.length >= game_data.team_participants && !isJoin">Join</b>
                <b @click="leave_show_team(item.team, item.team_name)" style="background-color: #ff0000;" v-if="isJoin && my_team_uuid == item.team && !isLeader && !isMaster">Leave</b>
              </span>
            </div>
          </div>
          <div class="btn2" @click="isConfirm3 = true" v-if="!isJoin && game_data.applied_team_count < game_data.max_number_of_team">+ Make a NEW team</div>
        </div>

      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm">
      <div class="con">
        <div class="head">
          <b @click="isConfirm = false">×</b>
        </div>
        <p>Do you want to delete this campaign ?</p>
        <div class="btn" @click="remove_game">Confirm</div>
      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm_delete_team">
      <div class="con">
        <div class="head">
          <b @click="isConfirm_delete_team = false">×</b>
        </div>
        <p>Are you sure to delete team “{{select_team_name}}” ?</p>
        <div class="btn" @click="delete_team">Confirm</div>
      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm_leave_team">
      <div class="con">
        <div class="head">
          <b @click="isConfirm_leave_team = false">×</b>
        </div>
        <p>Are you sure to leave team “{{select_team_name}}” ?</p>
        <div class="btn" @click="leave_team">Confirm</div>
      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm_join_team">
      <div class="con">
        <div class="head">
          <b @click="isConfirm_join_team = false">×</b>
        </div>
        <p>Are you sure to join team “{{select_team_name}}” ?</p>
        <div class="btn" @click="join_team">Confirm</div>
      </div>
    </div>

    <div class="confirmWindow" v-if="isConfirm3">
      <div class="con">
        <div class="head">
          <a>　</a>
          <p>+ Make a NEW team</p>
          <b @click="isConfirm3 = false">×</b>
        </div>
        <p><span>Team name</span><input v-model="new_team_name"/></p>
        <div class="btn" @click="create_team">CREATE</div>
      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm2">
      <div class="con">
        <div class="head">
          <a>　</a>
          <p>{{team_name}}</p>
          <b @click="isConfirm2 = false">×</b>
        </div>
        <div class="listCon">
          <div class="header">
            <span>Position</span>
            <span>Users</span>
            <span>Ladder points (wins)</span>
          </div>
          <div class="list">
            <div class="item" v-for="item,index in member_datas">
              <span>{{item.team_leader?'Leader':'Member'}}</span>
              <span>
                <img class="headimg" :src="item.user_profile_photo" v-if="item.user_profile_photo != null"/>
                <img class="headimg" src="../assets/img/default_logo.jpg" v-else/>
                <p>{{item.nick_name}}</p>
                <a @click="cutoff_show_member(item.user_uuid, item.nick_name)" v-if="isLeader && useruuid != item.user_uuid && my_team_uuid == select_team_id">×</a>
              </span>
              <span>{{item.user_score}} ({{item.profile_number_of_won}} / {{item.profile_total_battle}})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm_cutoff_member">
      <div class="con">
        <div class="head">
          <b @click="isConfirm_cutoff_member = false">×</b>
        </div>
        <p>Are you sure to cut off “{{select_member_name}}” ?</p>
        <div class="btn" @click="cutoff_member">Confirm</div>
      </div>
    </div>
    <HashBottom></HashBottom>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { postActionApi,getActionApi,deleteActionApi } from '@/network/manageApi'
  import { getAction,postAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  import HashBottom from "components/HashBottom"
  export default {
    name: 'JoinTeam',
    components: {
      HashBottom
    },
    data() {
      return {
        isConfirm2:false,
        isConfirm3:false,
        isConfirm:false,
        formLoading:false,
        uuid: '',
        game_data: {applied_team_count:0,max_number_of_team:0,team_participants:0},
        apped_number:0,
        member_datas: [],
        team_name: '',
        isMaster:false,
        isJoin: false,
        isLeader: false,
        my_team_uuid: '',
        new_team_name: '',
        isConfirm_delete_team: false,
        select_team_id: '',
        select_team_name: '',
        isConfirm_join_team: false,
        isConfirm_leave_team: false,
        isConfirm_cutoff_member: false,
        select_member_uuid: '',
        select_member_name: ''
      }
    },
    computed: {
      ...mapGetters(['useruuid'])
    },
    created(){
      if(!this.$route.query.uuid) {
        this.$router.push({path:'/'});
      } else {
        this.uuid = this.$route.query.uuid
      }
      this.init()
    },
    methods:{
      cutoff_show_member(uuid, nickname) {
        this.isConfirm_cutoff_member = true
        this.select_member_uuid = uuid
        this.select_member_name = nickname
      },
      cutoff_member() {
        this.formLoading = true;
        deleteActionApi("/game-management/team-management/team-member?game_uuid=" + this.uuid + "&team_number=" + this.select_team_id + "&member_uuid=" + this.select_member_uuid).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.$message.success("cut off successfully~");
            this.init()
          } else {
            this.$message.warning(res.message);
            this.formLoading = false;
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      init() {
        this.isConfirm_cutoff_member = false
        this.select_member_uuid = ''
        this.select_member_name = ''
        this.isConfirm_leave_team = false
        this.isConfirm_join_team = false
        this.isConfirm_delete_team = false
        this.select_team_id = ''
        this.select_team_name = ''
        this.formLoading = true
        this.new_team_name = ''
        this.isMaster = false
        this.isLeader = false
        this.isJoin = false
        this.my_team_uuid = ''
        this.team_name = ''
        this.member_datas = []
        this.apped_number = 0
        this.isConfirm2 = false
        this.isConfirm3 = false
        this.isConfirm = false
        getActionApi('/game-management/game-detail?game_uuid=' + this.uuid).then(res => {
          if (res.code == 200) {
            this.game_data = res.data.contents
            if(this.game_data.team_detail.length == 0) {
              this.$router.push({path:'/'});
            }
            //状态判断 started 跳转到比赛  end 去首页
            if(this.game_data.pvp_type == 'Solo') {
              if(this.game_data.game_status == 'started') {
                this.$router.push({path:'gamePlayMatch',query:{uuid:this.uuid}});
                return;
              } else if(this.game_data.game_status == 'end') {
                this.$router.push({path:'/'});
                return;
              } else {
                this.$router.push({path:'joinMatch',query:{uuid:this.uuid}});
                return;
              }
            } else {
              if(this.game_data.game_status == 'started') {
                this.$router.push({path:'gamePlayTeam',query:{uuid:this.uuid}});
                return;
              } else if(this.game_data.game_status == 'end') {
                this.$router.push({path:'/'});
                return;
              }
            }

            if(this.useruuid == this.game_data.user_uuid) {
              this.isMaster = true
            }
            for(let item of this.game_data.team_detail) {
              let user_score = 0
              let profile_total_battle = 0
              let profile_number_of_won = 0
              for(let member of item.members) {
                this.apped_number++;
                user_score += member.user_score
                profile_total_battle += profile_total_battle
                profile_number_of_won += member.profile_number_of_won
                if(this.useruuid == member.user_uuid) {
                  this.isJoin = true
                  this.my_team_uuid = item.team
                  this.isLeader = member.team_leader
                }
              }
              item.user_score = user_score
              item.profile_total_battle = profile_total_battle
              item.profile_number_of_won = profile_number_of_won
              if(item.team_name == null) {
                for(let member of item.members) {
                  if(member.team_leader) {
                    item.team_name = member.nick_name + " Team"
                  }
                }
              }
            }
          }
          this.formLoading = false
        })
      },
      remove_game() {
        this.formLoading = true;
        deleteActionApi("/game-management/my-game?game_uuid=" + this.uuid).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.$message.success("Delete successfully~");
            this.$router.push({path:'/'});
          } else {
            this.$message.warning(res.message);
            this.formLoading = false;
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      delete_show_team(team, team_name) {
        this.isConfirm_delete_team = true
        this.select_team_id = team
        this.select_team_name = team_name
      },
      join_show_team(team, team_name) {
        this.isConfirm_join_team = true
        this.select_team_id = team
        this.select_team_name = team_name
      },
      leave_show_team(team, team_name) {
        this.isConfirm_leave_team = true
        this.select_team_id = team
        this.select_team_name = team_name
      },
      leave_team() {
        this.formLoading = true;
        deleteActionApi("/game-management/team-management/team-member?game_uuid=" + this.uuid + "&team_number=" + this.select_team_id + "&member_uuid=" + this.useruuid).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.$message.success("Leave successfully~");
            this.init()
          } else {
            this.$message.warning(res.message);
            this.formLoading = false;
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      join_team() {
        this.formLoading = true;
        postActionApi("/game-management/join-game",{"game_uuid": this.uuid,"team" : this.select_team_id}).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.$message.success("Join successfully~");
            this.init()
          } else {
            this.$message.warning(res.message);
            this.formLoading = false;
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      delete_team() {
        this.formLoading = true;
        deleteActionApi("/game-management/team-management/" + this.select_team_id).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.$message.success("Delete successfully~");
            this.init()
          } else {
            this.$message.warning(res.message);
            this.formLoading = false;
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      auto_comple_zero(number) {
        return number.toString().length == 1?("0" + number):number
      },
      create_team() {
        if(this.new_team_name === '') {
          this.$message.error("Team name is required!");
          return;
        }
        this.$confirm('Do you want to create this team?', 'Tip', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        }).then(() => {
          this.formLoading = true;
          postActionApi("/game-management/join-game",{"game_uuid": this.uuid, "team":0, "team_name":this.new_team_name}).then(res => {
            this.formLoading = false;
            if(res.code == 200){
              this.$message.success("Create successfully~");
              this.init()
            } else {
              this.$message.warning(res.message);
              this.formLoading = false;
            }
          }).catch(err => {
            this.$message.warning(err.message);
            this.formLoading = false;
          })
        }).catch(() => {

        });
      },
      show_team_detail(team,team_name,array) {
        this.team_name = team_name
        this.member_datas = array
        this.select_team_id = team
        this.isConfirm2 = true
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'ABeeZee';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .title{font-size: 24px;color:#00FF00;margin-bottom:0px;}
  .title a{color:#657EFF;font-size: 24px;}
  .headimg {width:30px;height:30px;border-radius: 50%;border:2px #ffffff solid;flex-shrink: 0;}
  .content{display: flex;flex-direction: column;margin:auto;align-items: center;padding:30px 0 0 0;}
  .content .info{width:600px;display: flex;flex-direction: column;align-items: center;}
  .content .info hr{width:100%;height:0px;border:none;border-bottom:1px dashed #00FF00;margin:20px 0 6px 0;}
  .content .itemp{width:100%;display: flex;justify-content: flex-start;margin-top:4px;}
  .content .itemp p{width:100%;text-align: left;color:#ffffff;}
  .content .input{width:100%;min-height:30px;}
  .content .btn{width:160px;cursor: pointer;height: 40px;line-height:1;margin:10px auto 30px auto;background:#0500FF;color:#ffffff;border-radius: 20px;display: flex;align-items: center;justify-content: center;}
  .content .btn2{width:180px;cursor: pointer;height: 40px;line-height:1;margin:10px auto 0 auto;background:#0500FF;color:#ffffff;border-radius: 20px;display: flex;align-items: center;justify-content: center;}
  .content .act{background-color: #FF0000;}
  .listDiv{max-height:400px;margin-top:10px;overflow-y: auto;width:100%;}
  .header{background-color: #1DF200;display: flex;align-items: center;width:100%;}
  .header span{width:100%;font-size:14px;height:30px;display: flex;justify-content: center;align-items: center;margin: 10px 0;}
  .header span:nth-child(1){width:25%;justify-content: center;}
  .header span:nth-child(2){width:30%;justify-content: center;}
  .header span:nth-child(3){width:25%;}
  .header span:nth-child(4){width:20%;}
  .list{display: flex;flex-direction: column;}
  .list .item{background-color: #ffffff;display: flex;align-items: center;border:1px solid #1DF200;margin-top:2px;}
  .list .item span{display: flex;align-items: center;padding:6px 0;}
  .list .item span img{margin-right:10px;}
  .list .item span b{color:#ffffff;display: flex;cursor: pointer;align-items: center;justify-content: center;font-size:12px;background-color: #0500FF;width:60px;height:30px;border-radius: 6px;}
  .list .item span .act{background-color: #888888;}
  .list .item span:nth-child(1){width:25%;justify-content: center;}
  .list .item span:nth-child(2){width:30%;justify-content: center;color:#0020C9;}
  .list .item span:nth-child(3){width:25%;color:#0020C9;display: flex;justify-content: center;}
  .list .item span:nth-child(4){width:20%;justify-content: center;}
  .confirmWindow{position: fixed;top:0;left:0;width:100vw;height:100vh;display: flex;align-items:center;justify-content: center;}
  .confirmWindow .con{border:2px solid #00FF00;border-radius: 10px;background-color: #000000;width:600px;color:#ffffff;display: flex;flex-direction: column;padding:20px;align-items: center;}
  .confirmWindow .con p{font-size:26px;margin:30px 0;display: flex;align-items: center;justify-content: center;width:86%;}
  .confirmWindow .con p span{margin-right:5px;flex-shrink: 0;}
  .confirmWindow .con p input{text-align: center;height:30px;width:100%;}
  .confirmWindow .con .head{width:100%;display: flex;justify-content: flex-end;align-items: center;}
  .confirmWindow .con .head p{color:#ffffff;font-size:20px;text-align: center;width:100%;margin:0;}
  .confirmWindow .con .head a{width:24px;flex-shrink: 0;}
  .confirmWindow .con .head b{width:24px;flex-shrink: 0;height:24px;font-size:14px;display: flex;justify-content: center;align-items: center;color:#4A4A4A;border:2px solid #D0D0D0;background-color: #ffffff;cursor: pointer;border-radius: 4px;}
  .confirmWindow .con .btn{width:120px;cursor: pointer;height: 40px;line-height:1;margin:10px 0 30px 0;background:#0500FF;color:#ffffff;border-radius: 8px;display: flex;align-items: center;justify-content: center;}
  .confirmWindow .con .listCon{max-height:300px;margin-top:20px;overflow-y: auto;width:100%;}
  .confirmWindow .con .listCon .header{width:100%;color:#000000;}
  .confirmWindow .con .listCon .header span:nth-child(1){width:30%;}
  .confirmWindow .con .listCon .header span:nth-child(2){width:30%;}
  .confirmWindow .con .listCon .header span:nth-child(3){width:40%;}
  .confirmWindow .con .listCon .list .item{background-color: #000000;border:none;}
  .confirmWindow .con .listCon .list .item span:nth-child(1){width:30%;}
  .confirmWindow .con .listCon .list .item span:nth-child(2){color:#ffffff;display: flex;align-items: center;width:30%;}
  .confirmWindow .con .listCon .list .item span:nth-child(2) img{flex-shrink: 0;}
  .confirmWindow .con .listCon .list .item span:nth-child(2) p{width:100%;font-size:16px;margin:0;text-align: left;}
  .confirmWindow .con .listCon .list .item span:nth-child(2) a{width:16px;flex-shrink: 0;cursor: pointer;height:16px;border-radius: 50%;background-color: #D81E06;color:#ffffff;display: flex;align-items: center;justify-content: center;font-size:12px;margin-left:10px;}
  .confirmWindow .con .listCon .list .item span:nth-child(3){justify-content: center;padding-left:0;width:40%;}
  a{text-decoration: none;color:#5C6BC0;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .body{min-height:100vh;padding-top:60px;}
    .body *{font-size: 14px;}
    .title{margin:20px 0 6px 0 !important;font-size:24px !important;}
    .content{padding:0 0 10px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .listDiv{margin-top:10px;width:100%;}
    .list .item span b{}
    .confirmWindow .con{width:96vw;}
    .confirmWindow .con .listCon .list .item span:nth-child(2) img{width:18px;height:18px;}
    .confirmWindow .con .listCon .list .item span:nth-child(2) p{font-size:14px;}
    .confirmWindow .con .listCon .list .item span:nth-child(2) a{font-size:14px;}
    .content .btn{margin:10px auto;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
  }
</style>
